function importImages (req) {
  let images = {}
  req.keys().map((item, index) => {
    images[item.replace('./', '')] = req(item)
  })
  return images
}

const operatorImages = importImages(require.context('./assets/images', false, /\.(png|jpe?g)$/))

const operators = {
  woman_1: { name: 'Jenna', img: 'woman_1.jpg' },
  woman_2: { name: 'Michelle', img: 'woman_2.jpg' },
  woman_3: { name: 'Maria', img: 'woman_3.jpg' },
  woman_4: { name: 'Nikki', img: 'woman_4.jpg' },
  woman_5: { name: 'Sandra', img: 'woman_5.jpg' },
  man_1: { name: 'Steve', img: 'man_1.jpg' },
  man_2: { name: 'Thomas', img: 'man_2.jpg' },
  robot_1: { name: 'Johnny', img: 'robot_1.png' },
  robot_2: { name: 'Johnny', img: 'robot_2.png' },
  robot_3: { name: 'Charlie', img: 'robot_3.png' },
  elf_1: { name: 'Charlie', img: 'elf_1.png' },
}

Object.values(operators).forEach(v => {
  v.imageUrl = operatorImages[v.img] || null
})

export default operators
